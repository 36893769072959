
import { Box, Container, Paper, Stack, styled, Typography } from '@mui/material';
import React from 'react';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flexGrow: 1,
}));

const Ville = (props: { children: React.ReactNode; }) => {
    return <Item>{props.children}</Item>;
};

export const Emplacements = () => {
    return <Container>
        <Box sx={{ p: 4 }}>
            <Box>
                <Typography variant='h2' sx={{ p: 2, textAlign: 'center' }}>
                    Québec, Lévis et environs
                </Typography>

            </Box>
            <Box>
                <Box>
                    <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap">
                        <Ville>ARMAGH</Ville>
                        <Ville>BEAUMONT</Ville>
                        <Ville>BEAUPRE</Ville>
                        <Ville>BERTHIER-SUR-MER</Ville>
                        <Ville>BOISCHATEL</Ville>
                        <Ville>BUCKLAND</Ville>
                        <Ville>CHATEAU-RICHER</Ville>
                        <Ville>DONNACONA</Ville>
                        <Ville>FOSSAMBAULT-SUR-LE-LAC</Ville>
                        <Ville>HONFLEUR</Ville>
                        <Ville>L'ANCIENNE-LORETTE</Ville>
                        <Ville>LA DURANTAYE</Ville>
                        <Ville>LAC-BEAUPORT</Ville>
                        <Ville>LAC-DELAGE</Ville>
                        <Ville>LAC-SAINT-JOSEPH</Ville>
                        <Ville>LAC-SERGENT</Ville>
                        <Ville>LEVIS</Ville>
                        <Ville>MONTMAGNY</Ville>
                        <Ville>NEUVILLE</Ville>
                        <Ville>NOTRE-DAME-DU-ROSAIRE</Ville>
                        <Ville>PONT-ROUGE</Ville>
                        <Ville>QUEBEC</Ville>
                        <Ville>SAINT-ANSELME</Ville>
                        <Ville>SAINT-AUGUSTIN-DE-DESMAURES</Ville>
                        <Ville>SAINT-CHARLES-DE-BELLECHASSE</Ville>
                        <Ville>SAINT-DAMIEN-DE-BUCKLAND</Ville>
                        <Ville>SAINT-FABIEN-DE-PANET</Ville>
                        <Ville>SAINT-FERREOL-LES-NEIGES</Ville>
                        <Ville>SAINT-FRANCOIS-D'ORLEANS</Ville>
                        <Ville>SAINT-GABRIEL-DE-VALCARTIER</Ville>
                        <Ville>SAINT-GERVAIS</Ville>
                        <Ville>SAINT-HENRI-DE-LEVIS</Ville>
                        <Ville>SAINT-JEAN-D'ORLEANS</Ville>
                        <Ville>SAINT-LAURENT-ILE-D'ORLEANS</Ville>
                        <Ville>SAINT-LAZARE-DE-BELLECHASSE</Ville>
                        <Ville>SAINT-MICHEL-DE-BELLECHASSE</Ville>
                        <Ville>SAINT-NEREE</Ville>
                        <Ville>SAINT-PAUL-DE-MONTMINY</Ville>
                        <Ville>SAINT-PHILEMON</Ville>
                        <Ville>SAINT-PIERRE-ILE-D'ORLEANS</Ville>
                        <Ville>SAINT-RAPHAEL</Ville>
                        <Ville>SAINT-RAYMOND</Ville>
                        <Ville>SAINT-VALLIER</Ville>
                        <Ville>SAINTE-ANNE-DE-BEAUPRE</Ville>
                        <Ville>SAINTE-BRIGITTE-DE-LAVAL</Ville>
                        <Ville>SAINTE-CLAIRE</Ville>
                        <Ville>SAINTE-EUPHEMIE</Ville>
                        <Ville>SAINTE-FAMILLE</Ville>
                        <Ville>SAINTE-PETRONILLE</Ville>
                        <Ville>SHANNON</Ville>
                        <Ville>ST-FRANCOIS-DE-LA-RIVIERE-DU-S</Ville>
                        <Ville>ST-JOACHIM-DE-MONTMORENCY</Ville>
                        <Ville>ST-PIERRE-DE-LA-RIVIERE-DU-SUD</Ville>
                        <Ville>STE-CATHERINE-DE-LA-J-CARTIER</Ville>
                        <Ville>STONEHAM</Ville>
                        <Ville>PORTNEUF</Ville>
                        <Ville>SAINT-BASILE</Ville>
                        <Ville>SAINT-LONARD-DE-PORTNEUF</Ville>
                        <Ville>SAINTE-CHRISTINE-D'AUVERGNE</Ville>
                        <Ville>LAC-FRONTIÈRE</Ville>
                        <Ville>SAINTE-LUCIE-DE-BEAUREGARD</Ville>
                        <Ville>SAINTE-APOLLINE-DE-PATTON</Ville>
                        <Ville>CAP-SAINT-IGNACE</Ville>
                        <Ville>SAINT-ANTOINE-DE-L'ISLE-AUX-GRUES</Ville>
                        <Ville>SAULT-AU-COCHON</Ville>
                        <Ville>SAINT-TITE-DES-CAPS</Ville>
                        <Ville>LAC-JACQUES-CARTIER</Ville>
                        <Ville>SAINTE-PÉTRONILLE</Ville>
                        <Ville>LÉVIS</Ville>
                        <Ville>WENDAKE</Ville>
                        <Ville>SAINT-BERNARD</Ville>
                        <Ville>STE-MARGUERITE-DE-DORCHESTER</Ville>
                        <Ville>SAINTE-MARIE</Ville>
                        <Ville>SCOTT</Ville>
                        <Ville>SAINTE-HENEDINE</Ville>
                        <Ville>SAINT-ISIDORE</Ville>
                        <Ville>SAINT-LAMBERT-DE-LAUZON</Ville>
                        <Ville>SAINT-NARCISSE-DE-BEAURIVAGE</Ville>
                        <Ville>SAINT-GILLES</Ville>
                        <Ville>SAINT-AGAPIT</Ville>
                        <Ville>SAINT-APOLLINAIRE</Ville>
                        <Ville>SAINT-ANTOINE-DE-TILLY</Ville>
                        <Ville>SAINTE-CROIX</Ville>
                    </Stack>
                </Box>
            </Box>
        </Box>
    </Container>;
};
