
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AppRoutes } from 'appRoutes';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from "react-redux";
import { webStore } from './store/store';

const theme = createTheme({

    typography: {
        allVariants: {
            color: "#DDDDDD"
        },
        h1: {
            color: 'white',
            fontSize: '2.5rem'
        },
        h2: {
            color: 'white',
            fontSize: '2rem'
        }
    },
    palette: {
        text: {
            primary: '#E8E8E8',
            secondary: '#D3C9C5',
            disabled: '#897E79'
        },
        mode: 'dark',
        background: {
            default: '#000000'
        },
        primary: {
            main: '#EB6723'
        },
    }
    /*
    palette: {
        primary: {
            main: '#181515',
        },
        secondary: {
            main: '#ff0101',
        },
        background: {
            default: '#fafbf5',
        },
    },*/
});






const sendProto = XMLHttpRequest.prototype.send;
XMLHttpRequest.prototype.send = function (...args) {
    if (args && args[0] !== null) {
        console.log(args);

    }
    this.setRequestHeader('Frontend', '1');
    return sendProto.apply(this, args);
};


const container = document.getElementById('coreContainer');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<GoogleReCaptchaProvider
    reCaptchaKey='6LewFxAqAAAAAOCmOVj2AaWFAFPlKVb-1dlBRTWS'
    language="frCA"
    useEnterprise={true}
    scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body",
        nonce: undefined // optional, default undefined
    }}
>
    <Provider store={webStore}>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <AppRoutes />
            </BrowserRouter>
        </ThemeProvider>
    </Provider>
</GoogleReCaptchaProvider>);
