import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { combineReducers } from 'redux';
import { contactSlice } from './contactSlice';

export const appReducer = combineReducers({
    contact: contactSlice.reducer
});

export type AppState = ReturnType<typeof appReducer>;

const configureWebStore = () => configureStore({
    reducer: appReducer,
    preloadedState: {},
});


export const webStore = configureWebStore();
setupListeners(webStore.dispatch);
