import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import React, { FormEvent } from 'react';
import {
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { apiJson } from './api';
import { Gallery } from './Gallery';
import { contactSlice } from './store/contactSlice';
import { AppState, webStore } from './store/store';
import { PhoneInput } from './utils/PhoneInput';
/*
const onSubmitRecaptcha = (token: string) => {
    console.log('token', token);
    const autosumbmitElement = document.getElementById('autosubmit');
    const tokenElement = document.getElementById('token');
    if (autosumbmitElement && tokenElement) {
        (tokenElement as HTMLInputElement).value = token;
        (autosumbmitElement as HTMLInputElement).value = "1";
    }
};*/

export const Contact = (props: { test?: Boolean; }) => {

    const firstName = useSelector((state: AppState) => state.contact.firstName);
    const lastName = useSelector((state: AppState) => state.contact.lastName);
    const phone = useSelector((state: AppState) => state.contact.phone);
    const email = useSelector((state: AppState) => state.contact.email);
    const address = useSelector((state: AppState) => state.contact.address);
    const description = useSelector((state: AppState) => state.contact.description);
    const token = useSelector((state: AppState) => state.contact.token);
    const [error, setError] = React.useState<string[]>([]);
    const [sending, setSending] = React.useState(false);
    const formRef = React.useRef<HTMLFormElement | null>(null);
    const [autoSubmit, setAutoSubmit] = React.useState<string | undefined>();
    const [errors, setErrors] = React.useState<any[] | null>(null);

    const onSubmit = (_event?: FormEvent) => {
        _event?.preventDefault();
        const errs: string[] = [];
        if (phone == '') {
            errs.push('Veuillez indiquer un numéro de téléphone');
        }
        setError(errs);
        if (!errs.length) {
            sendMessage();
        }
    };
    const navigate = useNavigate();
    const sendMessage = async () => {
        setSending(true);
        setErrors(null);
        try {
            const response: SendMessageResult = await apiJson<SendMessageResult, SendMessageBody>(props.test === true ? '/sendMessageTest' : '/sendMessage', 'post', {
                firstName,
                lastName,
                address,
                description,
                email,
                phone,
                token
            });
            setSending(false);
            if (response === true) {
                /*
                if ('gtag' in window) {
                    (window as any).gtag('event', 'conversion', { 'send_to': 'AW-16641471259/qFNpCI-2usMZEJvmov89', 'transaction_id': (new Date()).toLocaleString().replace(/[^a-z0-9]/gi, '') });
                }
                    */
                webStore.dispatch(contactSlice.actions.clear());
                navigate('/messageSent');
            } else if (typeof response == 'object' && response.errors) {
                setErrors(response.errors);
            } else {
                setError([`Des problèmes techniques sont survenues. Impossible de transmettre votre demande.`]);
            }
        } catch (err: any) {
            setSending(false);
            setError([`Des problèmes techniques sont survenues. Impossible de transmettre votre demande.`]);
        }
    };

    const onCaptchaSubmit = (token: string | null) => {
        webStore.dispatch(contactSlice.actions.updateToken(token ?? ''));
    };


    return <><Container>
        <Box sx={{ alignItems: 'center', textAlign: 'center' }}>

            <Typography variant='h1' style={{ textAlign: 'center' }}>Demande de soumission</Typography>
            {sending && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={sending}
            >
                <CircularProgress size={4} color="inherit" />
            </Backdrop>}
            <Dialog
                open={error.length !== 0}
                onClose={() => { setError([]); }}
            >
                <DialogTitle>Attention</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {error.map(p => <p>{p}</p>)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setError([]); }}>OK</Button>
                </DialogActions>
            </Dialog>
            <form id="contactForm" ref={formRef} onSubmit={onSubmit}>
                <Box
                    sx={{ margin: '2em 0' }}>
                    <TextField
                        fullWidth
                        label="Prénom"
                        required
                        value={firstName}
                        onChange={(event) => {
                            webStore.dispatch(contactSlice.actions.updateFirstName(event.target.value));
                        }}
                        name='firstName'
                        error={!!errors?.find(p => p.param == 'firstName')}
                        helperText={errors?.find(p => p.param == 'firstName')?.msg ?? undefined}
                    />
                </Box>
                <Box
                    sx={{ margin: '2em 0' }}>
                    <TextField
                        fullWidth
                        required
                        label='Nom'
                        value={lastName}
                        onChange={(event) => {
                            webStore.dispatch(contactSlice.actions.updateLastName(event.target.value));
                        }}
                        name='lastName'
                        error={!!errors?.find(p => p.param == 'lastName')}
                        helperText={errors?.find(p => p.param == 'lastName')?.msg ?? undefined}
                    />
                </Box>
                <Box
                    sx={{ margin: '2em 0' }}>
                    <PhoneInput
                        fullWidth
                        required
                        label={'Numéro de téléphone'}
                        value={phone}
                        onChange={(value: string | null) => {
                            webStore.dispatch(contactSlice.actions.updatePhone(value ?? ''));
                        }}
                        name="phone"
                        error={!!errors?.find(p => p.param == 'phone')}
                        helperText={errors?.find(p => p.param == 'phone')?.msg ?? undefined}
                    />
                </Box>
                <Box
                    sx={{ margin: '2em 0' }}>
                    <TextField
                        fullWidth
                        label='Adresse courriel'
                        type='email'
                        value={email}
                        onChange={(event) => {
                            webStore.dispatch(contactSlice.actions.updateEmail(event.target.value));
                        }}
                        name='email'
                        error={!!errors?.find(p => p.param == 'email')}
                        helperText={errors?.find(p => p.param == 'email')?.msg ?? undefined}
                    />
                </Box>
                <Box
                    sx={{ margin: '2em 0' }}>
                    <TextField
                        fullWidth
                        label='Emplacement des travaux (Municipalité, région ou adresse)'
                        type='address'
                        value={address}
                        onChange={(event) => {
                            webStore.dispatch(contactSlice.actions.updateAddress(event.target.value));
                        }}
                        name='address'
                        error={!!errors?.find(p => p.param == 'address')}
                        helperText={errors?.find(p => p.param == 'address')?.msg ?? undefined}
                    />
                </Box>
                <Box
                    sx={{ margin: '2em 0' }}>
                    <TextField
                        fullWidth
                        multiline
                        rows={5}
                        label='Description de votre projet'
                        value={description}
                        onChange={(event) => {
                            webStore.dispatch(contactSlice.actions.updateDescription(event.target.value));
                        }}
                        name='description'
                        error={!!errors?.find(p => p.param == 'description')}
                        helperText={errors?.find(p => p.path == 'description')?.msg ?? undefined}
                    />
                </Box>
                <Box
                    sx={{ margin: '2em 0' }}>
                    <input type="hidden" id="token" name="token" value={token} onChange={event => webStore.dispatch(contactSlice.actions.updateToken(event.target.value))} />
                    <input type="hidden" id="autosubmit" name="autosubmit" value={autoSubmit} onChange={(event => setAutoSubmit(event.target.value))} />
                    {token === "" ? <GoogleReCaptcha
                        action='submit'
                        onVerify={token => {
                            onCaptchaSubmit(token);
                        }}
                    /> : null}
                    { /*<ReCAPTCHA
                    sitekey={RECAPTCHA_KEY ?? ''}
                    onChange={onCaptchaSubmit}
                /> */ }
                    <Button
                        disabled={token == ''}
                        type="submit"
                        color="primary"
                        variant="contained"
                    >Transmettre</Button>
                </Box>
                <Typography sx={{ fontSize: '0.7em' }}>Ce site est protégé par reCAPTCHA et la <a href="https://policies.google.com/privacy">politique de confidentialité</a> et les <a href="https://policies.google.com/terms">conditions d'utilisation</a> de Google s'appliquent.                </Typography>
                <Typography sx={{ minHeight: 100, m: 3 }}>La présente demande sera transmise à <Link to="mailto:quebec-levis@pieuxprotech.ca">quebec-levis@pieuxprotech.ca</Link></Typography>
            </form>
        </Box>
    </Container>
        <Container>
            <Gallery />
        </Container></>;
};
