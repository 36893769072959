
import { Box, Container, Paper, Stack, styled, Typography } from '@mui/material';
import React from 'react';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flexGrow: 1,
}));

const Ville = (props: { children: React.ReactNode; }) => {
    return <Item>{props.children}</Item>;
};

export const Applications = () => {
    return <Container>
        <Box sx={{ p: 4 }}>
            <Box>
                <Typography variant='h2' sx={{ p: 2, textAlign: 'center' }}>
                    Applications
                </Typography>

            </Box>
            <Box>
                <Box>
                    <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap">
                        <Ville>CLÔTURE</Ville>
                        <Ville>BALCON</Ville>
                        <Ville>PATIO</Ville>
                        <Ville>CABANON</Ville>
                        <Ville>FONDATION RÉSIDENTIELLE</Ville>
                        <Ville>EXTENSION RÉSIDENTIELLE</Ville>
                        <Ville>SOLARIUM</Ville>
                        <Ville>RÉPARATION DE FONDATION</Ville>
                        <Ville>DALLES ET ESCALIERS EN BÉTON</Ville>
                        <Ville>CHALET</Ville>
                        <Ville>PONT</Ville>
                        <Ville>PASSERELLE / SENTIER</Ville>
                        <Ville>ENSEIGNE</Ville>
                        <Ville>PANNEAU D'AFFICHAGE</Ville>
                        <Ville>PANNEAU SOLAIRE</Ville>
                        <Ville>LAMPARAIRE</Ville>
                        <Ville>MUR DE PROTECTION</Ville>
                        <Ville>TRAVAUX SUR MESURE</Ville>
                    </Stack>
                </Box>
            </Box>
        </Box>
    </Container>;
};
