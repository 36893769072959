
import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';


export const Competiteurs = () => {
    return <Container>
        <Box sx={{ p: 4 }}>
            <Box>
                <Typography variant='h2' sx={{ p: 2, textAlign: 'center' }}>
                    Pieux vissés et service d'installation qui défie la concurrence !
                </Typography>
            </Box>
            <Box>
                <Typography sx={{ textAlign: 'center', m: 2 }}>Les produits Pieux Protech : <strong>Pieux vissés</strong> <strong>galvanisés</strong> resistants, durables et <strong>garantis à vie</strong>. Excellente alternative aux <strong>sonotubes</strong>, les pieux Protech <strong>stabilisent</strong> votre <strong>patio</strong>,  <strong>galerie</strong>,  <strong>cabanon</strong>,  <strong>clôture</strong>, mais également votre maison, <strong>fondation</strong> ou projet d'<strong>extension de maison</strong>. Ils garantissent une <strong>stabilité</strong> sur un <strong>sol instable</strong>. Pour stabiliser et protéger votre investissment; <strong>construction neuve</strong>, <strong>bâtiment commercial</strong>, <strong>structure temporaire</strong>, <strong>bâtiments et structures résidentiels</strong>,  faites confiance à Pieux Protech !</Typography>
                <Typography sx={{ textAlign: 'center', m: 2 }}>Service d'installation : L'équipe Pieux protech, c'est l'avantage de <strong>15 ans d'expérience</strong>, une disponibilité et un service après vente qui défie la concurrence. N'hésitez pas à consulter <Link to="https://maps.app.goo.gl/2WBQK56izpbjQEpC9" target="_blank">les avis de notre clientèle sur Google</Link>.</Typography>
            </Box>
        </Box>
    </Container>;
};
