import { Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';


export const MessageSent = () => {

    const sendConversion = () => {
        if ('gtag' in window) {

            //(window as any).gtag('event', 'conversion', { 'send_to': 'AW-16641471259/qFNpCI-2usMZEJvmov89', 'transaction_id': (new Date()).toLocaleString().replace(/[^a-z0-9]/gi, '') });
            // @ts-ignore: Unreachable code error
            gtag('event', 'conversion', { 'send_to': 'AW-16641471259/qFNpCI-2usMZEJvmov89', 'transaction_id': (new Date()).toLocaleString().replace(/[^a-z0-9]/gi, '') });

        } else {
            console.log('cant send conversion');
        }
    };

    React.useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(sendConversion, 1000);
    }, []);

    return <Container>
        <Typography variant='h1' style={{ textAlign: 'center', fontSize: '3em' }}>Soumission envoyée :)</Typography>
        <Box sx={{ alignItems: 'center', textAlign: 'center' }}>
            <Typography sx={{ m: 2 }}>Votre demande de soumission a été envoyé avec succès. Nous y ferons suite dans le plus bref délais.</Typography>
            <Typography sx={{ m: 2 }}>Merci !</Typography>
            <Link to="https://lespieuxprotech.com">lespieuxprotech.com</Link>
        </Box>
        <Box sx={{ minHeight: 150 }} />
    </Container>;
};
