import { Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import React from 'react';


export const NotFound = () => {



    return <Container>
        <Box sx={{ alignItems: 'center', textAlign: 'center' }}>
            <Typography variant='h1' style={{ textAlign: 'center', fontSize: '3em' }}>Ooops</Typography>
            <Typography variant='h2' sx={{ m: 2 }}>La page recherchée n'existe pas.</Typography>
        </Box>
        <Box sx={{ minHeight: 150 }} />
    </Container>;
};
