import GoogleIcon from '@mui/icons-material/Google';
import { Button, Container, Grid } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export const Login = () => {
    return <Container>
        <Grid container>
            <Grid item xs={12} sm={8} md={6} lg={6}>
                <p><Button component={Link} to={'/auth/google'} startIcon={<GoogleIcon />}> Se connecter</Button></p>
                <p><Button component={Link} to='/auth/google/autrecompte' startIcon={<GoogleIcon />}> Utiliser un autre compte google</Button></p>
            </Grid>
        </Grid>
    </Container>;
};
