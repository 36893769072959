import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Box, Container } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';
import { Applications } from './Applications';
import { Competiteurs } from './Competiteurs';
import { Emplacements } from './Emplacements';
import { Footer } from './Footer';
import { useWindowDimensions } from './libraries/useWindowDimensions';

export const MasterViewPage = (props: { children?: ReactJSXElement; }) => {

    const windowDimensions = useWindowDimensions();


    const getContent = () => {
        return <><div style={{ minHeight: windowDimensions.height, backgroundColor: 'black' }}>
            <Box sx={{ textAlign: 'center' }}>
                <Box component='img' src="/PieuxProTechQuebecLevisBlanc.jpg" alt="Logo Pieux Protech Québec - Lévis" sx={{ height: 150 }} />
                <Box><Link to="https://lespieuxprotech.com">LesPieuxProtech.com</Link></Box>
            </Box>
            <Container maxWidth={false}>{props.children}</Container>

            <div id="seo">
                <div className='orange'>
                    <Competiteurs />
                </div>
                <div >
                    <Applications />
                </div>
                <div className='orange'>
                    <Emplacements />
                </div>
            </div>
            <Footer></Footer>
        </div>
        </>;
    };
    return <>{getContent()}</>;


};
