import { Container } from '@mui/material';
import Carousel, { ResponsiveType } from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import React from 'react';

const shuffle = <T,>(arr: T[]): T[] => {
    const a: any[] = [...arr];
    const b: any[] = [];
    while (a.length) {
        b.push(a.splice(Math.floor(Math.random() * a.length), 1)[0]);
    }
    return b;
};

const galleryCount: number = 20;

const GalleryItem = (props: { filename: string; }) => {
    return <img style={{ width: '100%' }} src={`/images/gallery/${props.filename}`} />;
};

export const Gallery = () => {


    const responsive: ResponsiveType = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 6,
            slidesToSlide: 2
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
            slidesToSlide: 2,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
            slidesToSlide: 3,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            slidesToSlide: 2
        }
    };

    const [orderedContent, sertOrderedContent] = React.useState<string[]>([]);

    React.useEffect(() => {
        const arr: string[] = [];
        for (let i = 1; i <= galleryCount; i++) {
            arr.push(i + '.jpg');
        }
        sertOrderedContent(shuffle(arr));
    }, []);
    return <Container>
        <Carousel autoPlay={true} infinite={true} autoPlaySpeed={5000} arrows={false} responsive={responsive}>
            {orderedContent.map(p => <GalleryItem key={p} filename={p} />)}
        </Carousel>
    </Container>;
};
