import { TextFieldProps } from '@mui/material';

import MuiPhoneNumber from 'material-ui-phone-number';
import React from 'react';

export const extraireNombres = (chaine: string): string => {
    // Utiliser une expression régulière pour retirer les caractères qui ne sont pas des consonnes
    return chaine.replace(/[^0123456789]/g, '');
};

export type PhoneInputProps = Omit<TextFieldProps, 'variant' | 'value' | 'onChange'> & {
    value?: string | null,
    onChange: (value: string | null) => void;
};

export const PhoneInput = (props: PhoneInputProps) => {
    const [inputValue, setInputValue] = React.useState(props.value ?? '');

    React.useEffect(() => {
        setInputValue(props.value ?? '');
    }, []);

    return <MuiPhoneNumber
        {...props}
        required
        defaultCountry='ca'
        onlyCountries={['ca']}
        value={inputValue}
        onChange={(event) => {
            const phoneNumeric: string = typeof event == 'string' ? extraireNombres(event) : extraireNombres(event?.target.value);
            props.onChange(phoneNumeric == '' || phoneNumeric == '1' ? null : phoneNumeric);
        }
        } />;
};
