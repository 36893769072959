import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PublicIcon from '@mui/icons-material/Public';
import { Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';

const FooterGrid = (props: { children: React.ReactNode; }) => {
    return <Grid item xs={12} sm={6} md={4} lg={4}>
        <div style={{ padding: 10, margin: 1, backgroundColor: '#111', borderTop: 'solid 3px white' }}>{props.children}</div>
    </Grid>;
};

export const Footer = () => {

    return <Container>
        <div style={{ height: '10px' }}></div>
        <Grid container rowSpacing={3} columnSpacing={3}>
            <FooterGrid>
                <Typography component='p'>
                    <strong>Pieux Protech - Québec Lévis Inc.</strong><br />
                    <em>Protégez votre investissement !</em>
                </Typography>
                <Typography component='p'>
                    <strong>Québec</strong>
                </Typography>
                <Typography component='p'>330 av. ST-MICHEL bureau A<br />QUÉBEC, QC<br /> G1C 1G5</Typography>
                <Typography component='p'>
                    <strong>Lévis</strong>
                </Typography>
                <Typography component='p'>110 Rue des Cèdres<br />Saint-Lambert-de-Lauzon, QC<br />G0S 2W0</Typography>
            </FooterGrid>
            <FooterGrid>

                <Typography component='p'>
                    <strong>Contact</strong>
                </Typography>
                <Typography component='p'>
                    <LocalPhoneIcon /> Tél.: <a href="tel:+15818900814">(581) 890-0814</a>
                </Typography>
                <Typography component='p'>
                    <EmailIcon /> Courriel: <a href="mailto:quebec-levis@pieuxprotech.ca">quebec-levis@pieuxprotech.ca</a>
                </Typography>
            </FooterGrid>
            <FooterGrid>

                <Typography component='p'>
                    <strong>Nous trouver sur Internet</strong>
                </Typography>
                <Typography component='p'>
                    <PublicIcon /> <a href="https://lespieuxprotech.com">lespieuxprotech.com</a>
                </Typography>
                <Typography component='p'>
                    <FacebookIcon /> <a href="https://www.facebook.com/profile.php?id=61556472324797">Page Facebook</a>
                </Typography>
                <Typography component='p'>
                    <LocationOnIcon /> <a href="https://maps.app.goo.gl/oq8Zn4iYu1RtpHVq7">Google maps</a>
                </Typography>
            </FooterGrid>
        </Grid>
    </Container >;
};
