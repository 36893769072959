
import React from 'react';
import { Route, Routes } from "react-router-dom";
import { Contact } from './Contact';
import { Login } from './Login';
import { MasterViewPage } from './MasterPage';
import { MessageSent } from './MessageSent';
import { NotFound } from './NotFound';


export const AppRoutes = () => {



    return <MasterViewPage><Routes>
        {SuperAdminRoutes()}
        {LoggedInRoutes()}
        <Route path={'/'} element={<Contact />} />
        <Route path={'/test'} element={<Contact test={true} />} />
        <Route path={'/messageSent'} element={<MessageSent />} />
        <Route path={'/login'} element={<Login />} />
        <Route path={'/*'} element={<NotFound />} />
    </Routes></MasterViewPage>;
};

const SuperAdminRoutes = () => {
    return <></>;
};
const LoggedInRoutes = () => {
    return <></>;
};
