
export const apiJson = async<R, B extends any>(url: string, method: 'get' | 'post', data?: B, options?: RequestInit) => {
    return new Promise<R>(async (resolve, reject) => {
        const strignifiedData: string | undefined = data ? JSON.stringify(data) : undefined;
        const reqParams = strignifiedData ? {
            method,
            body: strignifiedData,
            ...options,
            headers: {
                ...options?.headers ?? {},
                'Content-Type': 'application/json',
            },
        } : {
            method,
            ...options,
            headers: {
                ...options?.headers ?? {}
            },
        };
        try {
            fetch(url, reqParams).then(async (result) => {
                if (result.status >= 200 && result.status < 300) {
                    resolve(await result.json());
                } else {
                    reject(result.status);
                }
            }).catch(err => {
                // network error !
                console.log('err in json', err);
                console.log('navigator.onLine', navigator.onLine);
                reject(err);
            });
        } catch (error) {
            console.log('error');
            console.log('navigator.onLine', navigator.onLine);
        }

    });

};
