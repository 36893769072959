import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ContactState {
    firstName: string,
    lastName: string;
    email: string,
    phone: string;
    address: string;
    description: string;
    token: string;
}

const initialState: ContactState = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    description: '',
    token: ''
};

export const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        clear(state) {
            state.firstName = '';
            state.lastName = '';
            state.email = '';
            state.phone = '';
            state.address = '';
            state.description = '';
        },
        updateFirstName(state, action: PayloadAction<string>) {
            state.firstName = action.payload;
        },
        updateLastName(state, action: PayloadAction<string>) {
            state.lastName = action.payload;
        },
        updateEmail(state, action: PayloadAction<string>) {
            state.email = action.payload;
        },
        updatePhone(state, action: PayloadAction<string>) {
            state.phone = action.payload;
        },
        updateAddress(state, action: PayloadAction<string>) {
            state.address = action.payload;
        },
        updateDescription(state, action: PayloadAction<string>) {
            state.description = action.payload;
        },
        updateToken(state, action: PayloadAction<string>) {
            state.token = action.payload;
        },

    }
});
